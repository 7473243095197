// Import libraries
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';


// Import global styles
import GlobalStyle from '../styles/global';


// Import component
import Layout from '../layout/Layout';
import SEO from '../layout/SEO';


// Component styles
const StyledIntro = styled.div`
    & .content {
        display: grid;
        grid-template-rows: repeat(2, min-content);
        grid-row-gap: 2rem;
        max-width: var(--max-width);
        text-align: center;
        margin: 0 auto;
        padding: 10rem var(--padding-right) 10rem var(--padding-left);


        & h1 {
            font-size: var(--font-size-body-h1);
            font-weight: var(--font-weight-body-h1);
            line-height: var(--line-height-body-h1);
        }

        & .paragraph {
            font-size: var(--font-size-body-p);
            font-weight: var(--font-weight-body-p);
            line-height: var(--line-height-body-p);
        }
    }
`;

const StyledCustomerTestimonials = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 10rem;
    grid-row-gap: 10rem;
    padding: 0 var(--padding-right) 10rem var(--padding-left);

    @media only screen and (max-width: 1500px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media only screen and (max-width: 800px) {
        grid-template-columns: 1fr;
    }

    & .customer-testimonial {
        display: grid;
        grid-template-rows: repeat(2, max-content);
        grid-row-gap: 3rem;
        border: 1px solid var(--color-border-1);
        padding: 5rem;

        &.with-logo {
            grid-template-rows: 20rem repeat(2, max-content);
        }
        
        &__logo {
            display: grid;
            align-items: center;
            justify-items: center;
            align-content: center;
            grid-template-columns: 100%;
            width: 100%;

            & .image {
                width: 100%;
                max-width: 20rem;
                max-height: 20rem;
                margin: 0 auto;
            }
        }

        &__name {
            font-size: var(--font-size-body-h2);
            font-weight: 600;
            line-height: var(--line-height-body-h2);
        }

        &__message {
            font-size: var(--font-size-body-p);
            font-weight: var(--font-weight-body-p);
            line-height: var(--line-height-body-p);
            text-align: justify;
        }
    }
`;


// Create component
const KlantenPagina = () => {
    const data = useStaticQuery(graphql`
        query {
            customerLogoHotelZierikzee: file(relativePath: { eq: "customer-logos/hotel-zierikzee-logo.png" }) {
                childImageSharp {
                    fluid(maxWidth: 400, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }

            customerLogoDBMLogoCMYK: file(relativePath: { eq: "customer-logos/DBMLogoCMYK.png" }) {
                childImageSharp {
                    fluid(maxWidth: 400, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }

            customerLogoVLOTR: file(relativePath: { eq: "customer-logos/Vlotr-logo.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 400, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }

            customerLogoIThings: file(relativePath: { eq: "customer-logos/i-things-logo.jpeg" }) {
                childImageSharp {
                    fluid(maxWidth: 400, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }

            customerLogoUSDMonuta: file(relativePath: { eq: "customer-logos/LogoUSDMonuta.png" }) {
                childImageSharp {
                    fluid(maxWidth: 400, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }

            customerLogoSouvenirShopZierikzee: file(relativePath: { eq: "customer-logos/souvenir-shop-zierikzee-logo.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 400, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `);


    const customerTestimonials = [
        {
            id: 1,
            name: 'Hotel Zierikzee',
            logo: data.customerLogoHotelZierikzee.childImageSharp.fluid,
            message: 'Eindelijk een boekhouder die tijd voor je neemt zonder gelijk de klok aan te zetten. Als, niet boekhouders in de horeca, zijn wij enorm geholpen met een open en eerlijk persoon die aan huis of op het bedrijf het werk komt doen. Hij is nog vrij jong maar meedenkend en up-to-date. Bedankt Marcel! Jij hebt voor ons al heel wat geld bespaard!'
        },
        {
            id: 2,
            name: 'De Bruijne Media',
            logo: data.customerLogoDBMLogoCMYK.childImageSharp.fluid,
            message: 'Het is fijn samenwerken met Marcel. Hij neemt écht de tijd om je bedrijf te leren kennen en advies op maat te geven. En dat zonder onverwachte facturen. Zo\'n service vind je bijna nergens. Heel blij met Marcel!'
        },
        {
            id: 3,
            name: 'VLOTR',
            logo: data.customerLogoVLOTR.childImageSharp.fluid,
            message: 'Bij de start van mijn eigen bedrijf dacht ik de administratie er wel even bij te doen. Al snel liep ik achter de feiten aan. Gelukkig kwam Marcel van Up2Date Administratie op mijn pad. Marcel is meedenkend, heeft de juiste adviezen en alles loopt soepel. Samen doorgroeien. Aanrader!'
        },
        {
            id: 4,
            name: 'i-Things',
            logo: data.customerLogoIThings.childImageSharp.fluid,
            message: 'Een transparante en eerlijke boekhouder die met moderne software en tijdsgeest zijn klanten optimaal bediend. Een professioneel bedrijf met een klantgerichte instelling.'
        },
        {
            id: 5,
            name: 'Uitvaartverzorging Shouwen-Duiveland',
            logo: data.customerLogoUSDMonuta.childImageSharp.fluid,
            message: 'Fijn om alles onder &eacute;&eacute;n dak te hebben. Zowel boekhouding als salarisadministratie worden door Up2Date verzorgd. Altijd bereikbaar, zelfs via Whatsapp. Zeer aan te raden!'
        },
        {
            id: 6,
            name: 'Souvenir Shop Joni',
            logo: data.customerLogoSouvenirShopZierikzee.childImageSharp.fluid,
            message: 'Zeer goed bedrijf, houd je prima op de hoogte en is zeker Up2date.'
        },
        {
            id: 7,
            name: 'Mira\'s Browbar',
            logo: null,
            message: 'Hele fijne boekhouder. Neemt de tijd voor je, is heel open en denkt graag met je mee. Altijd goede service en is inderdaad up to date, wat hem professioneel maakt. Ik weet in ieder geval zeker dat ik graag met hem wil blijven samenwerken in de toekomst. Dankjewel Marcel!'
        },
        {
            id: 8,
            name: 'Jemma Wooldridge',
            logo: null,
            message: 'In juni laat op de middag gebeld met de vraag of ik met spoed geholpen kon worden, dit was geen enkel probleem! Zeer goed en persoonlijk geholpen.'
        },
        {
            id: 9,
            name: 'Nancy\'s Thuiszorg Van A tot Z',
            logo: null,
            message: 'Marcel is een geweldige boekhouder. Hij is goed op de hoogte van alle ontwikkelingen wil je altijd helpen waar hij kan. Het programma waar hij mee werkt maakt het voor mij een heel stuk makkelijker en overzichtelijker. Hij doet precies wat je van hem moet en kan verwachten en dan nog zoveel meer!!!! Met 1 woord top boekhouder!!!!!'
        },
        {
            id: 10,
            name: 'Jort Hardon',
            logo: null,
            message: 'Uiterst zorgzame en betrokken boekhouder. Neemt de tijd met je om je administratie tip top in orde te maken.'
        }
    ];


    return (
        <React.Fragment>
            <GlobalStyle />
            <SEO
                title="Klanten"
                description="De klant staat bij ons administratiekantoor centraal. Wij nemen de tijd om uw bedrijf te leren en passen onze diensten aan op uw onderneming."
            />
            <Layout>
                <StyledIntro>
                    <div className="content">
                        <h1>Onze Klanten</h1>
                        <p className="paragraph">Bij ons kantoor staat u, de klant, centraal. Naast digitale efficiency staat bij ons ook persoonlijk contact voorop. Iedere onderneming is anders. Zo nemen wij de tijd om uw bedrijf te leren kennen en onze diensten op maat aan te passen. Lees hieronder wat een aantal van onze klanten over ons zeggen.</p>
                    </div>
                </StyledIntro>

                <StyledCustomerTestimonials>
                    {customerTestimonials.map(testimonial => (
                        <div className={"customer-testimonial" + (testimonial.logo != null ? " with-logo" : "")} key={testimonial.id}>
                            {testimonial.logo != null && (
                                <div className="customer-testimonial__logo">
                                    <Img fluid={testimonial.logo} alt={testimonial.name + " logo"} className="image" />
                                </div>
                            )}
                            
                            <h2 className="customer-testimonial__name">{testimonial.name}</h2>
                            <p className="customer-testimonial__message" dangerouslySetInnerHTML={{ __html: testimonial.message }}></p>
                        </div>
                    ))}
                </StyledCustomerTestimonials>
            </Layout>
        </React.Fragment>
    );
};


// Export component by default
export default KlantenPagina;